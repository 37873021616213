import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Container from "../container"
import * as variable from "../variables"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FaFacebookF } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"
import { FaLinkedinIn } from "react-icons/fa"
import { FaPinterestP } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"

const FooterStyle = styled.footer`
  text-align: center;
  padding: 50px 0px;
  background-color: #3a3a3a;
  color: white;
  a {
    color: white !important;
    text-decoration: underline !important;
    padding: 0px 10px;
  }
  .footer-social {
    margin-top: 20px;
  }
  .footer-address{
    margin:20px 0px;
  }
`

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "kcchlogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      email: file(relativePath: { eq: "email.png" }) {
        childImageSharp {
          fixed(width: 13, height: 10) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      facebook: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fixed(width: 13, height: 13) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      instagram: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fixed(width: 13, height: 13) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      linkedin: file(relativePath: { eq: "linkedin.png" }) {
        childImageSharp {
          fixed(width: 11, height: 11) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const logo = data.logo.childImageSharp.fluid
  const email = data.email.childImageSharp.fixed
  const facebook = data.facebook.childImageSharp.fixed
  const instagram = data.instagram.childImageSharp.fixed
  const linkedin = data.linkedin.childImageSharp.fixed

  return (
    <FooterStyle>
      <Container>
        <div>
          <span>
            <Link to="/contact-us">Contact</Link>
          </span>
          Copyright © 2023 Kansas City Corporate Housing
          <span>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </span>
        </div>
        <div className="footer-address">
          <div>Located in: Country Club Plaza</div>
          <div>Address: 609 W 46th St Ste 907, Kansas City, MO 64112</div>
          <div>Phone: <a href="tel:844-816-1218">(844) 816-1218</a></div>
          <div>E-Mail: <a href="mailto:info@kansascitycorporatehousing.com">info@kansascitycorporatehousing.com</a></div>
        </div>
        <div className="footer-social">
          <span className="menu-social">
            <a
              target="_blank"
              href="https://www.facebook.com/KansasCityCorporateHousing/"
            >
              <FaFacebookF></FaFacebookF>
            </a>
          </span>
          <span className="menu-social">
            <a target="_blank" href="https://twitter.com/kc_corphousing">
              <FaTwitter></FaTwitter>
            </a>
          </span>
          <span className="menu-social">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/kc-corporate-housing"
            >
              <FaLinkedinIn></FaLinkedinIn>
            </a>
          </span>
          <span className="menu-social">
            <a
              target="_blank"
              href="https://www.instagram.com/kc_corphousing/?hl=en"
            >
              <FaInstagram></FaInstagram>
            </a>
          </span>
          <span className="menu-social">
            <a
              target="_blank"
              href="https://www.pinterest.com/corporatehousingkc"
            >
              <FaPinterestP></FaPinterestP>
            </a>
          </span>
        </div>
      </Container>
    </FooterStyle>
  )
}

export default Footer
